<template>
	<div class="box" v-loading.fullscreen.lock="fullscreenLoading">
		<div class="ct_box">
			<table class="table">
				<thead>
					<tr>
						<td>发票抬头</td>
						<td>发票类型</td>
						<td>税号</td>
						<td>邮箱</td>
						<td>收件人电话</td>
						<td>提交时间</td>
						<td>操作</td>
					</tr>
				</thead>
				<tbody>
					<tr v-for="(item, index) in tableData" :key="index">
						<td>{{ item.header }}</td>
						<td>{{ item.type == 1 ? '普通发票(6%税点)' : item.type == 2 ? '增值税专用发票(6%税点)' : '电子发票(1%税点)' }}</td>
						<td>{{ item.tax_num }}</td>
						<td>{{ item.email }}</td>
						<td>{{ item.tel }}</td>
						<td>{{ item.ctime }}</td>
						<td>
							<div>
								<span @click="upDateFn(item)">修改</span>
								<span @click="deleteFn(item)">删除</span>
							</div>
						</td>
					</tr>
				</tbody>
			</table>
			<!-- 分页器 -->
			<pagination :page="page" @pageChange="pageChange" :count="count" :total_page="total_page" />
		</div>
	</div>
</template>

<script>
export default {
	mounted() {
		this.get_list()
	},
	data() {
		return {
			total_page: 0,
			count: 0,
			page: 1,
			tableData: [],
			fullscreenLoading: false,
		}
	},
	methods: {
		pageChange(pageVal){
			this.page = pageVal
			this.get_list()
		},
		get_list() {
			let data = {}
			data.page = this.page
			this.curlPost('/api/users/invoice_info').then(res => {
				console.log(res);
				if (res.data.code) {
					this.tableData = res.data.data.list
					this.total_page = res.data.data.total_page
					this.count = res.data.data.count
				}
			})
		},
		upDateFn(item) {
			this.$router.push({
				path:'/user/AddInvoice',
				query:{
					id:item.id,
				}
			})
		},
		deleteFn(item) {
			this.fullscreenLoading = true
			this.curlPost('/api/users/invoice_info/del', {
				id: item.id
			}).then(res => {
				console.log(res);
				if (res.data.code) {
					this.get_list()
					this.fullscreenLoading = false

					return this.$message({
						message: '删除成功',
						type: 'success',
					})
				}
			})
		},
	},
}
</script>

<style lang="scss" scoped>
@import '@/scss/ApplyForInvoice';
</style>